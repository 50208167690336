// App.js or where your routes are defined
import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Routes, Route, Navigate } from 'react-router-dom';

const Marketplace = () => {
  const { instance } = useMsal();
  const handleRedirectToAzureSSO = async () => {
    const loginRequest = {
      scopes: ['openid', 'profile', 'email'], // Add necessary scopes
    };

    try {
      // Initiate login request
      await instance.loginRedirect(loginRequest);
    } catch (error) {
      console.error('Login redirect error:', error);
    }
  };

  // Redirect to Azure SSO on mount (optional)
  React.useEffect(() => {
    handleRedirectToAzureSSO();
  }, []);

  return <Navigate to="/" replace />;
};

export default Marketplace;
