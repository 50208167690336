import './App.css';
// import "../assets/fonts/inter.css";
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RoutePaths } from './RoutePaths';
import CookiePage from '../components/cookiePage/CookiePage';
import LandingPage from '../components/landingPage/LandingPage';
import NotFound from '../components/notFound/NotFound';
import { AccountLayout } from '../features/account/AccountLayout';
import { MarketPlaceLayout } from '../features/marketplaceSingleSignon/MarketPlaceLayout';

import { ForgotPassword } from '../features/account/forgotPassword/ForgotPassword';
import { Login } from '../features/account/login/Login';
import { ResetPassword } from '../features/account/resetPassword/ResetPasword';
import { SignUp } from '../features/account/signUp/SignUp';
import { CompanyLayout } from '../features/company/CompanyLayout';
import { ShopifyConnectorPage } from '../features/company/connectors/Shopify';
import { CompanyRegistration } from '../features/company/registration/CompanyRegistration';
import { ShopifyRegistration } from '../features/shopify/registration/shopify-registration';
import { ShopifyLayout } from '../features/shopify/shopify-layout';
import { Layout } from '../layout';
import { BillingResult } from '../pages/billing-result';
import { FacebookRedirect } from '../pages/facebook-redirect';
import { Finish } from '../pages/finish';
import { GoogleRedirect } from '../pages/google-redirect';
import { ShopifyInstall } from 'features/shopify/registration/components/shopify-install/shopify-install';
import { GoogleRedirectGA4 } from 'pages/google-redirect/google-redirectga4';
import { Elements } from '@stripe/react-stripe-js';
import getStripe from 'lib/getStripe';
import Checkout from 'components/stripe/Checkout';
import MarketPlace from 'features/marketplace/marketplace';
import { PublicClientApplication } from '@azure/msal-browser';
//import { msalConfig } from 'auth/authconfig';
import { MsalProvider } from '@azure/msal-react';
import { MarketPlaceLandingPage } from 'features/marketplaceSingleSignon/marketplaceLandingPage';
import { MsMarketingFinish } from 'features/marketplaceSingleSignon/finish';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECTURI,
  },
  cache: {
    cacheLocation: process.env.REACT_APP_CACHELOCATION,
    storeAuthStateInCookie: process.env.REACT_APP_STORE_AUTH_STATE_IN_COOKIE === 'true',
  },
};
// Initialize MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  useEffect(() => {
    document.title = 'Vennflow';
  }, []);

  const [isMsalInitialized, setIsMsalInitialized] = useState(false);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize(); // Handles redirect on page load
        setIsMsalInitialized(true);
      } catch (error) {
        console.error('MSAL initialization error:', error);
      }
    };

    initializeMsal();
  }, []);

  if (!isMsalInitialized) {
    return null; // Render nothing until MSAL is initialized
  }

  return (
    <SnackbarProvider>
      <MsalProvider instance={msalInstance}>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigate to={`/${RoutePaths.Account}/${RoutePaths.SignUp}`} replace />} />
            <Route element={<AccountLayout />}>
              <Route path={`${RoutePaths.Account}`}>
                <Route path={`${RoutePaths.SignUp}`} element={<SignUp />} />
                <Route path={`${RoutePaths.SignIn}`} element={<Login />} />
                <Route path={`${RoutePaths.ForgotPassword}`} element={<ForgotPassword />} />
                <Route path={`${RoutePaths.ResetPassword}/:code`} element={<ResetPassword />} />
                <Route path={`${RoutePaths.Finish}`} element={<Finish />} />
              </Route>
            </Route>
            <Route element={<CompanyLayout />}>
              <Route path={`${RoutePaths.Company}`}>
                <Route path={`${RoutePaths.CompanyRegistration}`} element={<CompanyRegistration />} />
                <Route path={`${RoutePaths.CompanyRegistration}/:companyId`} element={<CompanyRegistration />} />
              </Route>
            </Route>
            <Route element={<ShopifyLayout />}>
              <Route path={`${RoutePaths.Shopify}`}>
                <Route path={`${RoutePaths.ShopifyRegistration}`} element={<ShopifyRegistration />} />
              </Route>
            </Route>
            <Route element={<ShopifyLayout />}>
              <Route path={`${RoutePaths.BillingResult}`} element={<BillingResult />} />
              <Route path={`${RoutePaths.ShopifyRegistration}`} element={<ShopifyRegistration />} />
            </Route>

            <Route element={<MarketPlaceLayout />}>
              <Route path="/marketplacesubscription" element={<MarketPlaceLandingPage />} />
              <Route path="/marketplacefinish" element={<MsMarketingFinish />} />
            </Route>
            <Route path="/ShopifyStore" element={<ShopifyConnectorPage />} />
            <Route path="/GoogleRedirect" element={<GoogleRedirect />} />
            <Route path="/GoogleGA4Redirect" element={<GoogleRedirectGA4 />} />
            <Route path="/FacebookRedirect" element={<FacebookRedirect />} />
            <Route path={`/${RoutePaths.CookiePage}/:cookieCode/`}>
              <Route index element={<CookiePage />} />
              <Route path=":redirectPage" element={<CookiePage />} />
            </Route>
            <Route path="/LandingPage" element={<LandingPage />} />

            <Route path="/Shopify/Install" element={<ShopifyInstall />} />

            <Route path="/marketplace" element={<MarketPlace />} />

            {/* Marketplace route with SSO */}
            {/* <Route path={`/${RoutePaths.MarketPlace}`}>
            <Route element={<ProtectedRoute component={MarketPlace} msalInstance={msalInstance} />} />
          </Route> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </MsalProvider>
    </SnackbarProvider>
  );
}

export default App;
